import { version } from '@parcel/service-worker';

const STATIC_CACHE_NAME = `pos_static_cache_${version}`;
const DYNAMIC_CACHE_NAME = `pos_dynamic_cache_${version}`;
const OFFLINE_IMAGES = [
  '../images/toast-alert-icon.svg',
  '../images/offline-warning-icon.svg',
  '../images/offline-wifi-icon.svg',
  '../images/icon-home-active.svg'
];

const URL_EXCLUDE_LIST = ['.mp3', 'https://maps.googleapis.com/', 'https://maps.gstatic.com/'];

const OFFLINE_URL = 'offline.html';

self.addEventListener('install', event => {
  event.waitUntil(
    (async () => {
      const staticCache = await caches.open(STATIC_CACHE_NAME);
      await staticCache.addAll(OFFLINE_IMAGES);
      await staticCache.add(new Request(OFFLINE_URL, { cache: 'reload' }));
      await caches.open(DYNAMIC_CACHE_NAME);
    })()
  );
  console.debug('[Service Worker]: successfully installed.');
});

self.addEventListener('activate', event => {
  event.waitUntil(
    (async () => {
      // Find old cache instance and clean up
      const keys = await caches.keys();
      await Promise.all(keys.map(key => key !== (STATIC_CACHE_NAME || DYNAMIC_CACHE_NAME) && caches.delete(key)));
    })()
  );
  console.debug('[Service Worker]: successfully activated.');
});

self.addEventListener('fetch', event => {
  const { request } = event;
  // If request is made for web page url, it must contain http
  if (!(request.url.indexOf('http') === 0)) return;
  // If request method is POST, no-op
  if (request.method === 'POST') return;
  // If request mode is CORS, no-op
  if (request.mode === 'cors') return;

  for (let i = 0; i < URL_EXCLUDE_LIST.length; i++) {
    if (request.url.includes(URL_EXCLUDE_LIST[i])) {
      console.debug('[Service Worker]: Fetch event ignored. URL in exclude list.', request.url);
      return;
    }
  }

  event.respondWith(
    (async () => {
      try {
        // Check if response exists on cache and return it
        const cachedResponse = await caches.match(event.request);
        if (cachedResponse) return cachedResponse;

        // Only cache fetched responses that have an ok status
        const fetchedResponse = await fetch(event.request);
        if (fetchedResponse.ok) {
          const cache = await caches.open(DYNAMIC_CACHE_NAME);
          await cache.put(event.request.url, fetchedResponse.clone());
        }

        return fetchedResponse;
      } catch (err) {
        if (request.mode === 'navigate' && request.destination === 'document') {
          console.error('Fetch failed.', err);
          const cache = await caches.open(STATIC_CACHE_NAME);
          const cachedResponse = await cache.match(OFFLINE_URL);
          return cachedResponse;
        }
      }
    })()
  );
});

self.addEventListener('message', async event => {
  if (event.data && event.data.type === 'skip-waiting') {
    console.debug('[Service Worker]: Skipped waiting');
    self.skipWaiting();
  }

  if (event.data && event.data.type === 'clear-cache') {
    console.debug('[Service Worker]: Clearing cache');

    try {
      const keys = await caches.keys();
      await Promise.all(
        keys.map(key => {
          key === DYNAMIC_CACHE_NAME && caches.delete(key);
        })
      );
    } catch (err) {
      console.debug('[Service Worker]: Error in clearing cache');
      console.error(err);
    }
  }
});

/**
Saving a copy of the no-op service worker for future debugging purposes
*/

// self.addEventListener('install', function (event) {
//   self.skipWaiting();
//   // no-op at the moment
// });

// self.addEventListener('fetch', function (event) {
//   // no-op at the moment
// });

// self.addEventListener('activate', event => {
//   const staticCacheRegex = /pos_static_cache_[a-zA-Z0-9_]{8}/;
//   const dynamicCacheRegex = /pos_dynamic_cache_[a-zA-Z0-9_]{8}/;
//   event.waitUntil(
//     (async () => {
//       // Clean up all pos cache instances
//       const keys = await caches.keys();
//       await Promise.all(
//         keys.map(key => (staticCacheRegex.test(key) || dynamicCacheRegex.test(key)) && caches.delete(key))
//       );
//     })()
//   );
//   console.debug('[Service Worker]: successfully activated');
// });
